<template>
  <div id="app" class="app-container">
    <!-- Top banner -->
    <div class="banner">
      <img src="@/assets/banner.jpg" alt="Banner" />
    </div>

    <!-- Main container with sidebar and content below banner -->
    <div class="main-content">
      <Navbar />
      <div class="content">
        <MyHome />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MyHome from "@/components/MyHome.vue";

export default {
  name: 'App',
  components: {
    Navbar,
    MyHome,
  },
};
</script>

<style>
/* Overall dark theme */
body, #app {
  margin: 0;
  background-color: #1b2838;
  color: #c7d5e0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

/* Banner spans the top */
.banner {
  width: 100%;
}

.banner img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

/* Main container below banner, with sidebar and content */
.main-content {
  display: flex;
  flex: 1;
  height: calc(100vh - 300px); /* Full height minus banner height */
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}
</style>
