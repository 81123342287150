<template>
  <div :class="['sidebar', { expanded }]">
    <button class="toggle-button" @click="toggleSidebar">
      <span v-if="expanded">➖</span>
      <span v-else>➕</span>
    </button>
    <nav>
      <ul>
        <li>
          <a href="#">
            <i class="icon-home"></i>
            <span v-if="expanded">Home</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="icon-library"></i>
            <span v-if="expanded">Library</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="icon-community"></i>
            <span v-if="expanded">Community</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="icon-download"></i>
            <span v-if="expanded">Download Area</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 60px;
  background-color: #2a475e;
  color: #c7d5e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  transition: width 0.3s;
  height: 100%; /* Full height of main-content container */
}

.sidebar.expanded {
  width: 200px;
}

.toggle-button {
  background: none;
  border: none;
  color: #c7d5e0;
  font-size: 1.5em;
  cursor: pointer;
  margin-bottom: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

nav ul li {
  margin: 15px 0;
  text-align: center;
}

nav ul li a {
  color: #c7d5e0;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  transition: color 0.3s;
}

.icon-home::before {
  content: "🏠";
  margin-right: 8px;
}

.icon-library::before {
  content: "📚";
  margin-right: 8px;
}

.icon-community::before {
  content: "👥";
  margin-right: 8px;
}

.icon-download::before {
  content: "⬇️";
  margin-right: 8px;
}

nav ul li a:hover {
  color: #66c0f4;
}

nav ul li a span {
  display: inline-block;
  margin-left: 10px;
}
</style>
